<template>
  <icon-base
    :icon-name="name"
    :icon-color="color"
    :height="height"
    :width="width"
  >
    <component v-bind:is="iconSvg"></component>
  </icon-base>
</template>

<script>
import IconBase from "@/components/Icons/IconBase";

import AccessPointMinusIcon from "@/components/Icons/AccessPointMinusIcon";
import AccessPointIcon from "@/components/Icons/AccessPointIcon";
import AccountIcon from "@/components/Icons/AccountIcon";
import BusIcon from "@/components/Icons/BusIcon";
import CarIcon from "@/components/Icons/CarIcon";
import CowIcon from "@/components/Icons/CowIcon";
import DogSideIcon from "@/components/Icons/DogSideIcon";
import EyeIcon from "@/components/Icons/EyeIcon";
import FactoryIcon from "@/components/Icons/FactoryIcon";
import FireHydrantIcon from "@/components/Icons/FireHydrantIcon";
import FireIcon from "@/components/Icons/FireIcon";
import ForestIcon from "@/components/Icons/ForestIcon";
import FountainIcon from "@/components/Icons/FountainIcon";
import GoogleDownsaurIcon from "@/components/Icons/GoogleDownsaurIcon";
import HomeAnalyticsIcon from "@/components/Icons/HomeAnalyticsIcon";
import IvBagIcon from "@/components/Icons/IvBagIcon";
import LeafIcon from "@/components/Icons/LeafIcon";
import LightningBoltCircleIcon from "@/components/Icons/LightningBoltCircleIcon";
import MemoryIcon from "@/components/Icons/MemoryIcon";
import OrnamentIcon from "@/components/Icons/OrnamentIcon";
import PenguinIcon from "@/components/Icons/PenguinIcon";
import PipeValveIcon from "@/components/Icons/PipeValveIcon";
import RouterWirelessIcon from "@/components/Icons/RouterWirelessIcon";
import TowerFireIcon from "@/components/Icons/TowerFireIcon";
import TransmissionTowerIcon from "@/components/Icons/TransmissionTowerIcon";
import WatchVariantIcon from "@/components/Icons/WatchVariantIcon";
import WaterfallIcon from "@/components/Icons/WaterfallIcon";
import WaterPumpIcon from "@/components/Icons/WaterPumpIcon";
import MarkerIcon from "@/components/Icons/MarkerIcon";
import TemplateIcon from "@/components/Icons/TemplateIcon";

export default {
  components: { IconBase },
  props: {
    color: {
      type: String,
      default: "#00D63E",
    },
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
    },
    width: {
      type: [Number, String],
      default: 60,
    },
    height: {
      type: [Number, String],
      default: 60,
    },
  },
  component: {
    IconBase,
    AccessPointMinusIcon,
    AccessPointIcon,
    AccountIcon,
    BusIcon,
    CarIcon,
    CowIcon,
    ForestIcon,
    HomeAnalyticsIcon,
    MemoryIcon,
    PipeValveIcon,
    RouterWirelessIcon,
    TowerFireIcon,
    TransmissionTowerIcon,
    WatchVariantIcon,
    WaterfallIcon,
    WaterPumpIcon,
    TemplateIcon,
    DogSideIcon,
    EyeIcon,
    FactoryIcon,
    FireHydrantIcon,
    FireIcon,
    FountainIcon,
    GoogleDownsaurIcon,
    IvBagIcon,
    LeafIcon,
    LightningBoltCircleIcon,
    OrnamentIcon,
    PenguinIcon,
    MarkerIcon,
  },
  data() {
    return {
      icon: null,
    };
  },
  computed: {
    iconSvg() {
      switch (this.id) {
        case 1:
          return MarkerIcon;
        case 2:
          return AccessPointMinusIcon;
        case 3:
          return BusIcon;
        case 4:
          return CarIcon;
        case 5:
          return ForestIcon;
        case 6:
          return HomeAnalyticsIcon;
        case 7:
          return MemoryIcon;
        case 8:
          return PipeValveIcon;
        case 9:
          return RouterWirelessIcon;
        case 10:
          return TowerFireIcon;
        case 11:
          return TransmissionTowerIcon;
        case 12:
          return WatchVariantIcon;
        case 13:
          return WaterfallIcon;
        case 14:
          return WaterPumpIcon;
        case 15:
          return AccessPointIcon;
        case 16:
          return AccountIcon;
        case 17:
          return CowIcon;
        case 18:
          return DogSideIcon;
        case 19:
          return EyeIcon;
        case 20:
          return FactoryIcon;
        case 21:
          return FireHydrantIcon;
        case 22:
          return FireIcon;
        case 23:
          return FountainIcon;
        case 24:
          return GoogleDownsaurIcon;
        case 25:
          return IvBagIcon;
        case 26:
          return LeafIcon;
        case 27:
          return LightningBoltCircleIcon;
        case 28:
          return OrnamentIcon;
        case 29:
          return PenguinIcon;
        default:
          return MarkerIcon;
      }
    },
  },
};
</script>